/* eslint-disable */
const content = `
We know that one of the hardest days in Brazilian Jiu-Jitsu can be the first;  Trust us: You're going to have a good time, it's safe and people are really pretty cool.

Our goal in the BJJ 101 class is simple:  To get you to the point where you are ready to engage in live rolls (sparring).  In the process, you're going to learn the foundational elements of Jiu-Jitsu - techniques that you will carry with your entire Jiu-Jitsu journey.

The BJJ 101 course is a introductory program intended for individuals who wish to gain a practical and fundamental understanding of the sport. Accessible to beginners and intermediate athletes alike, students will learn takedowns, control positions (pins) and submission techniques. We'll spend time looking at the main positions of Brazilian Jiu-Jitsu (the guard, mount, back-mount etc), transitions between the positions and submissions from the positions we cover.

Jiu-Jitsu is an intellectually engaging and physically challenging sport.  You'll get in fighting shape.  You'll forge great friendships.  And you'll walk off the mats ready to handle what life throws at you.

Ask anyone who trains &mdash; they'll all tell you the exact same thing: "I wish I had started sooner."  We hope that you'll heed their advice.

The trail starts here.

`;

export default content
