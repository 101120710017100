import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
// import { HashLink } from 'react-router-hash-link'

import SlideIn from '../../utils/SlideIn'
import giImage from '../../../assets/bdjj-010.jpg'
// import noGiImage from '../../../assets/ashigarami.jpg'
import bJJ101Image from '../../../assets/bdjj-006.jpg'
import Teens1 from '../../../assets/IMG_3276.jpg'
import ComingSoonOverlay from '../../utils/ComingSoonOverlay'

import data from '../../../data'
import '../../../styles/Courses.scss'

function CourseBlock (props: any) {
  return (
    <Grid item sm={12} md={4}>
      { props.comingSoonOverlay
        ? (
        <Box my={2}>
          <ComingSoonOverlay>
            <Box
            className="courseImage"
            sx={{
              backgroundImage: `url(${props.image})`,
              height: {
                xs: '30vh',
                sm: '50vh',
                md: '30vh'
              }
            }}></Box>
          </ComingSoonOverlay>
        </Box>
          )
        : (
         <Box
         mt={2}
         mb={2}
         className="courseImage"
         sx={{
           backgroundImage: `url(${props.image})`,
           height: {
             xs: '30vh',
             sm: '50vh',
             md: '30vh'
           }
         }}></Box>
          )
      }
      <Typography variant="h4">{props.title}</Typography>
      <Typography
        variant="subtitle1"
        color="primary"
        mb={1}
        sx={{
          fontWeight: 'bold',
          textTransform: 'uppercase'
        }}
      >{props.subtitle}</Typography>
      <Typography
        variant="body1"
        mb={2}
      >{props.body}</Typography>
      <Link href={props.link}>Read More</Link>
    </Grid>
  )
}
const yaSubtitle = `Athletes age ${data.ya.age.min} - ${data.ya.age.max}`
export default function Courses () {
  return (
    <Box id="programs" className="marginContainerTall creme" py={8}>
      <SlideIn>
        <Container maxWidth="xl" disableGutters={true}>
          <Typography variant="h4">Programs</Typography>
          <Grid container spacing={2}>
            <CourseBlock
              title="Adult Jiu-Jitsu"
              image={giImage}
              subtitle="Gi & No-Gi Jiu-Jitsu"
              link="/courses"
              body="At The Black Dog Jiu-Jitsu Company we train Gi and No-Gi Jiu-Jitsu with equal emphasis.  We find that the intersection of these modes of training results in well-balanced and versitile skill sets that athetes easily translate into respective rulesets."
            />
            <CourseBlock
              title="Young Adult Jiu-Jitsu"
              image={Teens1}
              subtitle={yaSubtitle}
              link="/ya"
              body="Young athletes excel at The Black Dog Jiu-Jitsu Company. We provide a growth-centric environment for athletic achievement in Gi and No-Gi Jiu-Jitsu.  In session we prioritize fun -  with purpose.   Over time young athletes learn to train at same level of concentration and focus that is demonstrated by accomplished practitioners."
              imageAlign="top"
            />
             <CourseBlock
              title="BJJ 101"
              image={bJJ101Image}
              subtitle="Introduction to Fundamentals"
              link="/bjj101"
              body="The BJJ 101 course is a introductory program intended for individuals who wish to gain a practical and fundamental understanding of the sport.  Accessible to beginners and intermediate athletes alike, the BJJ 101 course is taught continuously through the year so that students can start class when it suits their schedule."
            />
          </Grid>
        </Container>
      </SlideIn>
    </Box>
  )
}
